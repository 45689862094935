<template>
  <div>
    <el-table
      :data="appealList"
      v-loading = "loading"
      element-loading-background = "rgba(0, 0, 0, 0.5)"
      element-loading-text = "数据正在加载中"
      element-loading-spinner = "el-icon-loading"
      style="width: 100%">
      <el-table-column
        label="创建时间">
        <template slot-scope="scope">
          {{ scope.row.createTime | formatDate }}
        </template>
      </el-table-column>
      <el-table-column
        prop="orderId"
        label="订单ID">
      </el-table-column>
      <el-table-column
        label="支付截图">
        <template slot-scope="scope">
          <img :src="item" v-for="item in scope.row.payImg.split(',')">
        </template>
      </el-table-column>
      <el-table-column
        prop="remark"
        label="备注">
      </el-table-column>
      <el-table-column
        label="操作">
        <template slot-scope="scope">
          <el-button type="text" @click="audit('1',scope.row.id)">通过</el-button>
          <el-button type="text" @click="audit('2',scope.row.id)">退回</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div v-if="appealForm.total > 0" ref="pagebar" class="pagebar">
      <el-pagination
        :page-sizes="[5,10,20, 50, 100, 300]"
        :current-page="appealForm.page"
        :page-size="appealForm.limit"
        :total="appealForm.total"
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handlePageChange"
        @size-change="handleLimitChange"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "orderAppeal",
  data() {
    return {
      loading:false,
      appealList:[],
      appealForm:{
        page:1,
        limit:10,
        total:0,
      }
    }
  },
  mounted() {
    this.getAppealList()
  },
  methods: {
    async getAppealList() {
      try {
        this.loading = true
        let appeal = await this.axios.form("/api/admin/order/appeal")
        this.appealList = appeal.appeals
        this.appealForm.page = appeal.page.page
        this.appealForm.limit = appeal.page.limit
        this.appealForm.total = appeal.page.total
      } catch (e) {
        this.$notify.error({title: "错误", message: e.message});
      }
      this.loading = false
    },
    handlePageChange(val){
      this.appealForm.page = val
      this.getAppealList()
    },
    handleLimitChange(val){
      this.appealForm.limit = val
      this.getAppealList()
    },
    async audit(val,id){
      console.log(val)
      console.log(id)
      if (val === '1'){
        try{
          await this.axios.form("/api/admin/order/makeup/"+id)
          this.$message({ message: "补单成功", type: "success" });
          await this.getAppealList()
        }catch (e){
          this.$notify.error({title: "错误", message: e.message});
        }
      }else if (val === '2'){
        let me = this
        this.$confirm("确认要退回申请？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          showInput:true,
          inputPlaceholder:"请输入退回原因",
        }).then((val) => {
          this.axios
            .form("/api/admin/order/makeup/back/"+id, {
              msg: val.value,
            }).then((data) => {
            me.$message({ message: "退回成功", type: "success" });
            me.getAppealList();
          }).catch((rsp) => {
            me.$notify.error({ title: "错误", message: rsp.message });
          });
        }).catch(() => {
          this.$message({
            type: "info",
            message: "已取消审核",
          });
        });
      }
    }
  }
}
</script>

<style scoped>

</style>
